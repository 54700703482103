//格式化数据
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { getUserSecrecyConfig } from '@api/common/cache';

/**
 * 查询结果格式化, Promise
 * @export
 * @param {*} response
 * @param {*} type
 */
export function formatUserDataPromise(response, type) {
  if (type != null && type > 0) {
    response.then(res => {
      if (res.data != null) {
        formatUserData(res.data, type);
      }
    });
  }
}

/**
 * 按用户设置格式化
 * 默认用户没有权限，关健信息显示 *
 * 正常显示，金额 ##,###
 * type: 1默认只格式化金额，2按用户保密设置格式化
 */
export function formatUserData(data, type) {
  let config = {
    //默认没权限，隐藏
    customerName: '1', //客户姓名
    mobile: '1', //联系方式
    identification: '1', //证件号
    address: '1', //地址
    email: '1', //email
    accountNo: '1', //银行帐号
    //amount: '1', //金额
    //rate: '1', //收益率
  };
  let promiseSecrecy = new Promise(function(resolve, reject) {
    if (type == DATA_FORMAT_TYPE.USRE_SETUP) {
      //类型是按用户保密设置，检查用户设置中是否有需要保密
      getUserSecrecyConfig()
        .then(res => {
          config = res;
          resolve();
        })
        .catch(e => {
          reject();
        });
    } else {
      resolve();
    }
  });

  //隐藏数据
  promiseSecrecy.then(res => {
    //检查是否需要格式化
    let isNeedUserFormat = false; //是否需要用户设定格式化，如果不需要，则只格式化金额千分位
    if (DATA_FORMAT_TYPE.USRE_SETUP == type) {
      //如果是按用户格式，检查是否有需要格式的字段
      for (let key in config) {
        if (config[key] == '1') {
          //上面配置任何一个为1需要
          isNeedUserFormat = true;
          break;
        }
      }
    }
    //格式化数据
    if (data instanceof Array) {
      if (isNeedUserFormat) {
        //按用户设置格式化
        for (let item of data) {
          formatDataSecret(config, item);
        }
      } else {
        //只格式化金额数字
        for (let item of data) {
          formatObjectAmountToString(item);
        }
      }
    } else {
      let item = data;
      if (isNeedUserFormat) {
        formatDataSecret(config, item);
      } else {
        formatObjectAmountToString(item);
      }
    }
  });
}

/**
 * 用户数据保密格式化
 * @param {*} config
 * @param {*} data
 */
function formatDataSecret(config, data) {
  for (let key in data) {
    let v = data[key];
    if (v != null) {
      let k = key.toLowerCase();
      if (config.customerName == '1') {
        //客户姓名
        if (k.endsWith('customername')) {
          data[key] = v.replace(/(?<=.{1})./g, '*');
        }
      }
      if (config.mobile == '1') {
        //联系方式
        if (k.endsWith('mobile')) {
          data[key] = v.replace(/(\d{3})\d*(\d{4})/g, '$1****$2');
        }
      }
      if (config.identification == '1') {
        //证件号
        if (k.endsWith('identification')) {
          data[key] = v.replace(/(\d{6})\d+/g, '$1******');
        }
      }
      if (config.address == '1') {
        //地址
        if (k.endsWith('address')) {
          data[key] = v.replace(/(.{6}).*/g, '$1******');
        }
      }
      if (config.email == '1') {
        //email
        if (k.endsWith('email')) {
          data[key] = v.replace(/\w+(@\w+)/g, '******$1');
        }
      }
      if (config.accountNo == '1') {
        //银行帐号
        if (k.endsWith('accountno')) {
          data[key] = v.replace(/(\d{6})\d+(\d{3})/g, '$1******$2');
        }
      }
      if (isAmountField(key)) {
        if (!isNaN(data[key])) {
          //金额
          let strV = parseFloat(v).toFixed(2); //强制2位小数，不足补0;
          if (config.amount == '1') {
            data[key] = strV.replace(/\d+(\.\d*)?/g, '***$1');
          } else {
            data[key] = formatAmountToString(strV); //千分位
          }
        }
      }
      if (config.rate == '1') {
        //收益率
        if (k.endsWith('rate')) {
          let strV = parseFloat(v).toFixed(2);
          data[key] = strV.replace(/\d+(\.\d*)?/g, '***$1');
        }
      }
    }
  }
}

//日期格式化
export function formatDate(date, fmt) {
  if (fmt == null || fmt == '') {
    fmt = 'yyyy-MM-dd';
  }
  let o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
      );
    }
  }
  return fmt;
}

/**
 * 对象金额格式千分位
 * @param {*} object
 */
export function formatObjectAmountToString(object) {
  for (let key in object) {
    try {
      let v = object[key];
      if (v != null && !isNaN(v)) {
        if (isAmountField(key)) {
          let strV = v.toString();
          object[key] = formatAmountToString(strV); //千分位
        }
      }
    } catch (e) {
      console.dir(e);
    }
  }
}

/**
 * 对象金额格式回数字
 * @param {*} object
 */
export function formatObjectAmountToNumber(object) {
  for (let key in object) {
    try {
      let v = object[key];
      if (v != null && typeof v == 'string' && v.indexOf(',') > 0) {
        if (isAmountField(key)) {
          object[key] = formatAmountToNumber(v);
        }
      }
    } catch (e) {
      console.dir(e);
    }
  }
}

/**
 * 数值格式化千分位
 * @param {*} data
 */
export function formatAmountToString(data) {
  let strNum = '0';
  if (data != null) {
    data = parseFloat(data).toFixed(2); //强制2位小数，不足补0
    strNum = data.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'); //千分位
  }
  return strNum;
}

/**
 * 千分位金额重新转化成原数值
 * @param {*} data
 */
export function formatAmountToNumber(data) {
  let num = 0;
  if (data != null && data != '') {
    if (isNaN(data)) {
      num = data.replace(/,/g, '');
      num = parseFloat(num);
    } else {
      num = parseFloat(data);
    }
  }
  return num;
}

/**
 * 将金额转成对应的中文大写，小数2位。如果length要求，前面补全到指定位数
 * @param {} amount 金额
 * @param {} length 整数格式化指定位数
 * @param {} char 前面补全字符
 */
export function formatAmountToChinese(amount, length, char) {
  let chinese = '';
  if (amount != null && amount != '') {
    let arr = amount.toString().split('.');
    if (arr[1] == null || arr[1].length != 2) {
      //如果没有小数，后面补0到2位
      arr[1] = formatNumberLength(arr[1], 2, '0', '2');
    }
    let arrInt = arr[0].split(''); //整数部分转数组
    let arrFloat = arr[1].split(''); //小数部分转数组
    if (length != null && char != null) {
      //前面补全指定字符
      let n = length - arr[0].length;
      chinese += Array(n)
        .fill(char)
        .join('');
    }
    for (let i = 0; i < arrInt.length; i++) {
      chinese += formatNumberToChinese(arrInt[i]);
    }
    chinese += '.';
    for (let i = 0; i < arrFloat.length; i++) {
      chinese += formatNumberToChinese(arrFloat[i]);
    }
  }
  return chinese;
}

/**
 * 补全数字到指定位数
 * @param {} number 数字
 * @param {} length 补全位数
 * @param {*} char 补全字符
 * @param {*} type 类型，1前面补全，2后面补全，默认前面
 */
export function formatNumberLength(number, length, char, type) {
  let newNumber = null;
  if (type == null) {
    //默认前面补全
    type = '1';
  }
  if (number == null) {
    newNumber = Array(length + 1).join(char);
  } else {
    if (type == '1') {
      newNumber = (Array(length).join(char) + number).slice(-length);
    } else if (type == '2') {
      newNumber = (number + Array(length).join(char)).slice(0, length);
    }
  }
  return newNumber;
}

/**
 * 数字转中文大写
 * @param {*} number 一位数字
 */
export function formatNumberToChinese(number) {
  let chinese = '';
  if (number == null || number == '') {
    chinese = 'ⓧ';
  } else if (!isNaN(number) && number.length == 1) {
    let arrayNumberChinese = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    for (let i = 0; i < 10; i++) {
      if (parseInt(number) == i) {
        chinese = arrayNumberChinese[i];
        break;
      }
    }
  }
  return chinese;
}

/**
 * 银行帐号4位分隔
 * @param {*} bankAccountNo//银行帐号
 */
export function formatBankAccountNo(bankAccountNo) {
  let newNo = '';
  if (bankAccountNo != null && bankAccountNo != '') {
    newNo = bankAccountNo.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
  }
  return newNo;
}

/**
 * 属性是否表示金额数字
 */
function isAmountField(field) {
  let isAmount = false;
  if (field != null && field != '') {
    field = field.toLowerCase();
    let arrayField = [
      'amount',
      'cash',
      'cache',
      'earnings',
      'interest',
      'bonus',
      'deduct',
      'debtor',
      'fee',
    ]; //金额部分字段名
    let arrayFieldNotInclude = [
      'remark',
      'desc',
      'label',
      'text',
      'time',
      'feededucttype',
      'interesttotal',
      'interestoriginal',
      'interestassign',
    ]; //不包含字段名
    for (let i = 0; i < arrayField.length; i++) {
      //字段包含属性
      if (field.indexOf(arrayField[i]) > -1) {
        isAmount = true;
        break;
      }
    }
    for (let j = 0; j < arrayFieldNotInclude.length; j++) {
      //字段不包含属性
      if (field.indexOf(arrayFieldNotInclude[j]) > -1) {
        isAmount = false;
        break;
      }
    }
  }
  return isAmount;
}
